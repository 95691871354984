// BookingUserPage.duck.js

import { fetchBySlug } from '../../util/api'

// ================ ACTION TYPES ================ //

export const FETCH_BY_SLUG_REQUEST = 'app/BookingUserPage/FETCH_BY_SLUG_REQUEST'
export const FETCH_BY_SLUG_SUCCESS = 'app/BookingUserPage/FETCH_BY_SLUG_SUCCESS'
export const FETCH_BY_SLUG_ERROR = 'app/BookingUserPage/FETCH_BY_SLUG_ERROR'

// ================ REDUCER ================ //

const initialState = {
  userData: null,
  fetchBySlugInProgress: false,
  fetchBySlugError: null
}

const bookingUserReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_BY_SLUG_REQUEST:
      return { ...state, fetchBySlugInProgress: true, fetchBySlugError: null }
    case FETCH_BY_SLUG_SUCCESS:
      return { ...state, fetchBySlugInProgress: false, userData: payload }
    case FETCH_BY_SLUG_ERROR:
      return { ...state, fetchBySlugInProgress: false, fetchBySlugError: payload }
    default:
      return state
  }
}

export default bookingUserReducer

// ================ ACTION CREATORS ================ //

export const fetchBySlugRequest = () => ({
  type: FETCH_BY_SLUG_REQUEST
})

export const fetchBySlugSuccess = (userData) => ({
  type: FETCH_BY_SLUG_SUCCESS,
  payload: userData
})

export const fetchBySlugError = (error) => ({
  type: FETCH_BY_SLUG_ERROR,
  payload: error
})

// ================ SELECTORS ================ //

export const selectBookingUserPage = (state) => state.BookingUserPage
export const selectUserData = (state) => selectBookingUserPage(state).userData
export const selectFetchBySlugInProgress = (state) =>
  selectBookingUserPage(state).fetchBySlugInProgress
export const selectFetchBySlugError = (state) => selectBookingUserPage(state).fetchBySlugError

// ================ THUNKS ================ //

export const fetchBySlugThunk =
  ({ slug }) =>
  async (dispatch) => {
    dispatch(fetchBySlugRequest())
    try {
      const result = await fetchBySlug({ slug })
      if (result.response) {
        dispatch(fetchBySlugSuccess(result.response))
      } else {
        dispatch(fetchBySlugError(result.error))
      }
    } catch (error) {
      console.error('Error fetching user data by slug:', error)
      dispatch(fetchBySlugError(error.toString()))
    }
  }

// ================ LOAD DATA ================ //

export const loadData = (params, search) => async (dispatch) => {
  const cleanedSlug = params?.slug?.split(/[?&]/)?.[0] || ''
  await dispatch(fetchBySlugThunk({ slug: cleanedSlug }))
}

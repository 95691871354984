// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import Decimal from 'decimal.js'
import appSettings from '../config/settings'
import { types as sdkTypes, transit } from './sdkLoader'

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`
  }

  // Otherwise, use the same domain and port as the frontend
  return `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}`
}

export const AUTH_DESTINATION_SIGN_IN = 'sign_in'
export const AUTH_DESTINATION_REGISTER = 'register'

export const loginUrl = (from, login_hint) => {
  return authUrlWithHint(AUTH_DESTINATION_SIGN_IN, { from, login_hint })
}

export const signUpUrl = (from) => {
  return authUrlWithHint(AUTH_DESTINATION_REGISTER, { from })
}

export const authUrlWithHint = (first_screen, params = {}) => {
  const { from, login_hint, firstName, lastName, userType } = params
  const searchParams = new URLSearchParams({
    first_screen: first_screen,
    ...(from && { from: from }),
    ...(login_hint && { login_hint: login_hint }),
    ...(firstName && { firstName: firstName }),
    ...(lastName && { lastName: lastName }),
    ...(userType && { userType: userType })
  })

  return `${apiBaseUrl()}/api/auth/logto?${searchParams.toString()}`
}

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: (v) => new sdkTypes.BigDecimal(v.toString()),
    reader: (v) => new Decimal(v.value)
  }
]

const serialize = (data) => {
  return transit.write(data, {
    typeHandlers,
    verbose: appSettings.sdk.transitVerbose
  })
}

const deserialize = (str) => {
  return transit.read(str, { typeHandlers })
}

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json'
    },
    body: serialize(body)
  }

  return fetch(url, options).then((res) => {
    const contentTypeHeader = res.headers.get('Content-Type')
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null

    if (res.status >= 400) {
      return res.json().then((data) => {
        let e = new Error('An error occured')
        e = Object.assign(e, data)

        throw e
      })
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize)
    } else if (contentType === 'application/json') {
      return res.json()
    }
    return res.text()
  })
  // .catch((err) => {
  //   console.error('Error123:', err)
  // })
}

const get = (path, body) => {
  const url = `${apiBaseUrl()}${path}`
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json'
    }
    // body: serialize(body)
  }

  return fetch(url, options).then((res) => {
    const contentTypeHeader = res.headers.get('Content-Type')
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null

    if (res.status >= 400) {
      return res.json().then((data) => {
        let e = new Error('An error occurred')
        e = Object.assign(e, data)

        throw e
      })
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize)
    } else if (contentType === 'application/json') {
      return res.json()
    }
    return res.text()
  })
}

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = (body) => {
  return post('/api/transaction-line-items', body)
}

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = (body) => {
  return post('/api/initiate-privileged', body)
}

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = (body) => {
  return post('/api/transition-privileged', body)
}

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = (body) => {
  return post('/api/auth/create-user-with-idp', body)
}

export const submitBookingUserEmail = (body) => {
  return post('/api/user/request-to-book', body)
}

export const notifyPublishListing = (body) => {
  return post('/api/notify-publish-listing', body)
}

export const addReviewToListing = (body) => {
  return post('/api/add-review', body)
}
export const addReviewResponseToListing = (body) => {
  return post('/api/add-review-respone', body)
}

export const updateReviewForListing = (body) => {
  return post('/api/update-review', body)
}
export const updateReviewResponseForListing = (body) => {
  return post('/api/update-review-response', body)
}

export const fetchReviewsOfListing = (body) => {
  return post('/api/fetch-reviews', body)
}
export const deleteReviewOfListing = (body) => {
  return post('/api/delete-review', body)
}

export const deleteReviewResponseOfListing = (body) => {
  return post('/api/delete-review-response', body)
}

export const writeProfileBio = (body) => {
  return post('/api/write-profile-bio', body)
}

export const rewriteProfileBio = (body) => {
  return post('/api/rewrite-profile-bio', body)
}

export const askQuestion = (body) => {
  return post('/api/ask-question', body)
}

export const fetchModelRequests = (body) => {
  return post('/api/fetch-model-requests', body)
}

export const fetchNearbyConsultRequests = (body) => {
  return post('/api/fetch-nearby-consult-requests', body)
}

export const replyConsultRequest = (body) => {
  return post('/api/reply-consult-request', body)
}

export const claimVoucher = (body) => {
  return post('/api/claim-voucher', body)
}

export const emailVerified = (body) => {
  return post('/api/email-verified', body)
}

export const hasPassword = (body) => {
  return post('/api/has-password', body)
}

export const changePasswordRequest = (body) => {
  return post('/api/change-password-request', body)
}

export const identifyUser = (body) => {
  return post('/api/identify-user', body)
}

export const getDiscount = (body) => {
  return post('/api/get-discount', body)
}

export const shareCart = (body) => {
  return post('/api/share-cart', body)
}

export const trackListingEvent = (body) => {
  return post('/api/track-listing-event', body)
}

export const markReviewAsHelpful = (body) => {
  return post('/api/mark-review-helpful', body)
}

export const markReviewAsUnhelpful = (body) => {
  return post('/api/mark-review-unhelpful', body)
}

export const fetchListing = (queryParams, body) => {
  return get(`/api/fetch-listing?${convertToQueryParamsString(queryParams)}`, body)
}

export const fetchPrivateListing = (queryParams, body) => {
  return get(`/api/fetch-private-listing?${convertToQueryParamsString(queryParams)}`, body)
}

export const fetchCurrentUser = () => {
  return get('/api/fetch-current-user')
}

export const toggleFavoriteListing = async (body) => {
  return post('/api/toggle-favorite-listing', body)
}

export const syncListing = async (body) => {
  return post('/api/sync-listing', body)
}

export const changeEmailRequest = async (body) => {
  return post('/api/change-email-request', body)
}

export const verifyVerificationCode = async (body) => {
  return post('/api/verify-verification-code', body)
}

export const deleteAccount = async (body) => {
  return post('/api/delete-account', body)
}

export const fetchConsultRequests = async () => {
  return get('/api/fetch-consult-requests')
}

export const closeConsultRequest = async (body) => {
  return post('/api/close-consult-request', body)
}

export const addConsultRequestFeedback = async (body) => {
  return post('/api/add-consult-request-feedback', body)
}

export const fetchListingBookingConfig = async () => {
  return get(`/api/fetch-listing-booking-config`)
}

export const updateBookingConfigurations = async (queryParams, body) => {
  return post(`/api/update-booking-configurations?${convertToQueryParamsString(queryParams)}`, body)
}

export const updateBookingCalendars = async (body) => {
  return post('/api/update-booking-calendars', body)
}

export const updateBookingPolicy = async (body) => {
  return post('/api/update-booking-policy', body)
}

export const saveBookingSlug = async (body) => {
  return post('/api/save-booking-slug', body)
}

export const updateNotificationConfiguration = async (body) => {
  return post('/api/update-notification-configuration', body)
}

export const addGoogleCalendar = async () => {
  return get('/api/add-google-calendar')
}

export const fetchBySlug = async (queryParams) => {
  return get(`/api/fetch-by-slug?${convertToQueryParamsString(queryParams)}`)
}

export const fetchAvailability = async (queryParams) => {
  return get(`/api/fetch-availability?${convertToQueryParamsString(queryParams)}`)
}

export const bookingRequest = async (body) => {
  return post('/api/booking-request', body)
}

export const fetchClientBookingDetails = async (queryParams) => {
  return get(`/api/fetch-client-booking-details?${convertToQueryParamsString(queryParams)}`)
}

export const cancelClientBooking = async (queryParams, body) => {
  return post(`/api/cancel-client-booking?${convertToQueryParamsString(queryParams)}`, body)
}

export const fetchPractitionerBookingConfig = async (queryParams) => {
  return get(`/api/fetch-practitioner-booking-config?${convertToQueryParamsString(queryParams)}`)
}

export const fetchPractitionerEventData = async (queryParams) => {
  return get(`/api/fetch-practitioner-event-data?${convertToQueryParamsString(queryParams)}`)
}

export const fetchPractitionerBooking = async (queryParams) => {
  return get(`/api/fetch-practitioner-booking?${convertToQueryParamsString(queryParams)}`)
}

export const updatePractitionerBookingAction = async (queryParams) => {
  return post(`/api/update-practitioner-booking-action?${convertToQueryParamsString(queryParams)}`)
}

export const updatePractitionerEventAction = async (queryParams, body) => {
  return post(
    `/api/update-practitioner-event-action?${convertToQueryParamsString(queryParams)}`,
    body
  )
}

export const startStripeDashboardSession = async () => {
  return get('/api/start-stripe-dashboard-session')
}

export const getStripeOnboardingLink = async (queryParams) => {
  return get(`/api/get-stripe-onboarding-link?${convertToQueryParamsString(queryParams)}`)
}

export const getStripeExpressDashboardLink = async () => {
  return get('/api/get-stripe-express-dashboard-link')
}

export const fetchProviderClients = async () => {
  return get('/api/fetch-provider-clients')
}

export const saveProviderClient = async (queryParams, body) => {
  return post(`/api/save-provider-client?${convertToQueryParamsString(queryParams)}`, body)
}

export const hcpCreateEvent = async (body) => {
  return post('/api/hcp-create-event', body)
}

export const getClientDashboard = async () => {
  return get('/api/get-client-dashboard')
}

export const getClientBookings = async () => {
  return get('/api/get-client-bookings')
}

export const getClientSkinReportPdfUrl = async () => {
  return get('/api/get-client-skin-report-pdf-url')
}

export const getClientRewards = async () => {
  return get('/api/get-client-rewards')
}

export const joinSkinChatWaitlist = async () => {
  return post('/api/join-skin-chat-waitlist')
}

export const startSkinAnalyserScan = async (params) => {
  return get(`/api/start-skin-analyser-scan?${convertToQueryParamsString(params)}`)
}

export const getPreviewSkinReport = async (params) => {
  return get(`/api/get-preview-skin-report?${convertToQueryParamsString(params)}`)
}

export const hcpRequestAnalyser = async () => {
  return post('/api/hcp-request-analyser')
}

export const getHcpDashboard = async () => {
  return get('/api/get-hcp-dashboard')
}

export const getHcpSkinReportPdfUrl = async () => {
  return get('/api/get-hcp-skin-report-pdf-url')
}

export const getHcpListings = async () => {
  return get('/api/get-hcp-listings')
}

export const hcpEnrollStellaShop = async () => {
  return post('/api/hcp-enroll-stella-shop')
}

export const hcpJoinClientCrmWaitlist = async () => {
  return post('/api/hcp-join-client-crm-waitlist')
}

export const hcpAcceptStellaCredit = async () => {
  return post('/api/hcp-accept-stella-credit')
}

const convertToQueryParamsString = (params) => {
  if (!params) {
    return ''
  }

  const keys = Object.keys(params)
  return keys
    .filter((key) => !!params[key]) // Exclude keys with undefined values
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
}
